import styled from '@emotion/styled';
import { Link } from '@pafcloud/base-components';
import { Color, Font, FontTextSize, deviceWithHover } from '@pafcloud/style';

export const HeaderMenuLink = styled(Link)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',

  fontFamily: Font.Primary,
  fontSize: FontTextSize.Big,
  fontWeight: 800,
  textRendering: 'optimizeLegibility',
  color: Color.TextColors.MutedText,
  outline: 'none',

  // Current Tab decoration
  '::after': {
    content: '""',
    position: 'absolute',

    right: -4,
    bottom: 0,
    left: -4,
    height: 0,
    width: 'auto',
    background: Color.Primitive.Primary,
    transition: 'height 0.3s, bottom 0.34s',
  },

  // Current link
  '&[aria-current="true"]': {
    color: Color.TextColors.HighlightedText,

    // Current Tab decoration
    '::after': {
      height: 2,
    },
  },

  [deviceWithHover]: {
    ':hover': {
      color: Color.TextColors.HighlightedText,
    },

    // Focus tab decoration
    '&:focus-visible::after': {
      height: 2,
      bottom: 2,
      background: Color.TextColors.MutedText,
    },
  },
});
