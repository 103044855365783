import styled from '@emotion/styled';
import type { TouchEvent } from 'react';
import { forwardRef, useRef } from 'react';
import { Color, Breakpoint, ZIndex, DynamicValues, FontTextSize } from '@pafcloud/style';

import { ButtonReset, Icon, Logo } from '@pafcloud/base-components';
import { MainMenuItemIcon } from '../MainMenuIcons';
import { MainMenuItem } from '../MainMenuItem';
import type { SidebarProps } from './Sidebar';

type NavProps = {
  isOpen: boolean;
  'data-open': boolean;
  onTouchMove: (event: TouchEvent) => void;
  onTouchStart: (event: TouchEvent) => void;
};

const Nav = styled.nav<NavProps>(({ isOpen }) => ({
  position: 'fixed',
  top: DynamicValues.GamePauseHeight,
  bottom: 0,
  left: 0,

  width: 'min(320px, calc(100vw - var(--full-width-margin)))',
  paddingBottom: 56,
  overflowY: 'auto',
  zIndex: ZIndex.Header + 1,
  transition: `transform 0.300s 0s, box-shadow 0.2s 0.1s, filter 0.2s 0.15s ease-out`,
  transform: isOpen ? undefined : 'translateX(-100%)',
  background: Color.Layout.Sidebar.Background,
  color: Color.Layout.Sidebar.Foreground,

  filter: isOpen ? undefined : 'brightness(0.6)',
  overscrollBehavior: 'contain',

  '::-webkit-scrollbar': {
    display: 'none',
  },

  [Breakpoint.LaptopOrLarger]: {
    '::-webkit-scrollbar': {
      display: 'unset',
    },
  },

  '& + main': {
    '--content-offset': 'min(100px, 50%)',

    [Breakpoint.LaptopOrLarger]: {
      '--content-offset': '100px',
    },
    transition: 'transform 0.35s',
    transform: isOpen ? 'translateX(var(--content-offset))' : undefined,
  },

  // Light blobs
  '::before': {
    content: '""',
    position: 'absolute',
    zIndex: -1,
    top: 0,
    right: 0,
    left: 0,
    height: '100%',
    opacity: 0.2,
    background: `
      radial-gradient(closest-side, ${Color.Primitive.Primary}80 0%, transparent),
      radial-gradient(closest-side, ${Color.TextColors.BodyText}20 0%, transparent)`,
    backgroundSize: `
      800px 800px,
      500px 500px`,
    backgroundPosition: `
      -400px -450px,
      -275px 100px`,
    backgroundRepeat: 'no-repeat',
  },
}));

const NavContent = styled.div({
  '--scale-timing': '0.1s',
  transition: 'transform var(--scale-timing) 0.1s ease-in-out ',
  transform: 'scale(0.9)',

  '[data-open="true"] &': {
    '--scale-timing': '0.2s',
    transform: 'scale(1)',
  },

  display: 'flex',
  flexDirection: 'column',
  height: '100.1%', // Always make sidebar scrollable, required for overscroll behavior to work

  '> ul': {
    margin: '0 12px',

    [`${MainMenuItem}`]: {
      minHeight: 48,
      marginBottom: 8,
      borderRadius: 4,
      isolation: 'isolate',
      transition: 'font-variation-settings 0.2s ease-out',
      fontSize: FontTextSize.Big,

      '&[aria-current="location"]::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        borderRadius: 4,
        zIndex: -1,

        background: Color.Primitive.Primary,
      },
    },

    [`${MainMenuItemIcon}`]: {
      fontSize: '0.8em',
      marginRight: 16,
    },
  },
});

const SidebarHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 24,
});

const CloseButton = styled.button(ButtonReset, {
  width: 32,
  height: 32,
  borderRadius: 4,
  background: Color.TextColors.MutedText,
  color: Color.Primitive.SecondaryContrast,
  transform: 'skew(-9deg)',
  transition: 'transform 0.2s ease-out',
  textAlign: 'center',

  ':hover, :focus': {
    background: Color.TextColors.HeadingText,
    outline: 'transparent',
    transform: 'skew(-9deg) scale(1.05)',
  },

  '> span': {
    height: '100%',
    transform: 'skew(9deg)',
  },

  svg: {
    height: 16,
    transform: 'scale(-1) translateX(0.25px)',
    stroke: 'currentcolor',
  },
});

const SidebarLogo = styled(Logo)({
  height: 28,
  marginBottom: 6,
});

export const Sidebar = forwardRef<HTMLElement, SidebarProps>(({ onClose, isOpen, children }, ref) => {
  const touchClientXRef = useRef<number | null>(null);

  const handleTouchStart = (event: TouchEvent) => {
    touchClientXRef.current = event.targetTouches[0].clientX;
  };

  const handleTouchMove = (event: TouchEvent) => {
    if (touchClientXRef.current && isOpen) {
      const touchDistance = touchClientXRef.current - event.targetTouches[0].clientX;
      const touchOffset = 80;

      if (touchDistance > touchOffset) {
        onClose();
      }
    }
  };

  return (
    <Nav
      isOpen={isOpen}
      ref={ref}
      data-testid="main-menu"
      data-open={isOpen}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
    >
      <NavContent>
        <SidebarHeader>
          <SidebarLogo />
          <CloseButton onClick={onClose}>
            <Icon name="closeThinRounded" />
          </CloseButton>
        </SidebarHeader>
        {children}
      </NavContent>
    </Nav>
  );
});

Sidebar.displayName = 'Sidebar';
