import LogoNoText from './svg/speedybet-es-logo-no-text.svg';
import LogoDefault from './svg/speedybet-es-logo-horizontal.svg';
import type { LogotypeType } from './logo-variants-types';

export const Logos: LogotypeType = {
  Horizontal: LogoDefault,
  HorizontalInverse: LogoDefault,
  Vertical: LogoDefault,
  VerticalInverse: LogoDefault,
  Icon: LogoNoText,
  IconInverse: LogoNoText,
};
