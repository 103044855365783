import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,
  TrackBackground: Color.Surface.Base.Background,
  TrackBackgroundChecked: `${Color.Primitive.Accent}10`,

  ThumbBackground: Color.Primitive.Secondary,
  ThumbBackgroundChecked: Color.Primitive.Accent,
};
