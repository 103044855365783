import { Color } from '@pafcloud/style';

export const Colors = {
  Text: Color.TextColors.BodyText,
  TextHover: Color.Primitive.Primary,
  TextActive: Color.Primitive.PrimaryContrast,
  TextShadow: undefined,

  Icon: Color.TextColors.MutedText,
  IconActive: 'currentColor',

  BackgroundHover: undefined,
  BackgroundActive: Color.Primitive.Primary,

  ItemDivider: undefined,
  ItemActiveShadow: undefined,

  SubMenuBackground: Color.Surface.Base.Background,
  SubMenuRuler: Color.Surface.Base.Dimmed,
};

export const FontStyle = {
  TextTransform: undefined,
  Weight: 900,
  WeightHover: 900,
  WeightActive: 900,
} as const;
