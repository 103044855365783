import { Color } from '@pafcloud/style';
import type { ButtonColorsProps } from '../ButtonProps';
import { Colors as Colors_default } from './colors.default';

/**
 * State: Disabled
 * Variant: Secondary
 */
const secondaryDisabled = {
  TextDisabled: Color.Surface.Disabled.Foreground,
  BackgroundDisabled: Color.Surface.Disabled.Background,
  BorderDisabled: Color.Surface.Disabled.Background,
};

/**
 * Color: Default
 * Variant: Secondary
 */
const secondaryDefault: ButtonColorsProps = {
  Text: Color.Primitive.Primary,
  Background: Color.Primitive.Primary + '20',
  Border: 'transparent',

  TextHover: Color.Primitive.Primary,
  BackgroundHover: Color.Primitive.Primary + '30',
  BorderHover: 'transparent',

  TextActive: Color.Primitive.PrimaryTint,
  BackgroundActive: Color.Primitive.Primary + '30',
  BorderActive: 'transparent',

  FocusRing: Color.Primitive.Primary + '80',
  ShadowTint: 'transparent',

  ...secondaryDisabled,
};

/**
 * Color: Accent
 * Variant: Secondary
 */
const secondaryAccent: ButtonColorsProps = {
  ...secondaryDefault,
};

export const Colors = {
  ...Colors_default,
  secondaryDefault,
  secondaryAccent,
};
