import { createTransparentColor } from '../create-transparent-color';
import type { ColorDefinition } from './color-types';

const MomentumNeonPalette = {
  Midnight: '#091417',

  // Primary Colors
  Green: '#00f8a3',
  GreenDarker: '#15564a',

  //Secondary Colors
  Red: '#F80055',

  // Gradients
  GradientStart: '#00f8a3',
  GradientEnd: '#b0ffe4',

  Black: '#000B0A',
  Darker: '#0c2423',
  Dark: '#2d3d3d',
  Light: '#84a5a2',
  Lightest: '#cedbda',
  White: '#ffffff',

  // Extra
  Yellow: '#F8A300',
  Error: '#ff3333',
};

const Primitive: ColorDefinition['Primitive'] = {
  Primary: MomentumNeonPalette.Green,
  PrimaryTint: MomentumNeonPalette.GradientEnd,
  PrimaryContrast: MomentumNeonPalette.Black,

  Secondary: MomentumNeonPalette.White,
  SecondaryTint: MomentumNeonPalette.GradientEnd,
  SecondaryContrast: MomentumNeonPalette.Darker,

  Accent: MomentumNeonPalette.Green,
  AccentTint: MomentumNeonPalette.GradientEnd,
  AccentContrast: MomentumNeonPalette.Black,

  Gradient: MomentumNeonPalette.Green,
  GradientTint: MomentumNeonPalette.GradientEnd,
  GradientContrast: MomentumNeonPalette.Black,
};

const TextColors: ColorDefinition['TextColors'] = {
  HeadingText: MomentumNeonPalette.White,
  BodyText: MomentumNeonPalette.White,
  MutedText: MomentumNeonPalette.Light,
  HighlightedText: MomentumNeonPalette.White,
  LinkText: MomentumNeonPalette.Green,
  ErrorText: MomentumNeonPalette.Yellow,
  DisabledText: MomentumNeonPalette.Dark,
};

const Surface: ColorDefinition['Surface'] = {
  Base: {
    Background: MomentumNeonPalette.Black,
    Foreground: MomentumNeonPalette.Lightest,
    Dimmed: createTransparentColor(MomentumNeonPalette.Lightest, 0.25),
  },
  Nested: {
    Background: MomentumNeonPalette.Darker,
    Foreground: MomentumNeonPalette.Lightest,
    Dimmed: createTransparentColor(MomentumNeonPalette.Green, 0.15),
  },
  Disabled: {
    Background: MomentumNeonPalette.Dark,
    Foreground: MomentumNeonPalette.Light + '80',
    Dimmed: createTransparentColor(MomentumNeonPalette.Darker, 0.15),
  },
  Floating: {
    Background: MomentumNeonPalette.Midnight,
    Foreground: MomentumNeonPalette.Lightest,
    Dimmed: createTransparentColor(MomentumNeonPalette.Lightest, 0.15),
  },
};

const Signal: ColorDefinition['Signal'] = {
  Success: MomentumNeonPalette.Green,
  SuccessContrast: MomentumNeonPalette.Darker,

  Info: MomentumNeonPalette.GradientEnd,
  InfoContrast: MomentumNeonPalette.GreenDarker,

  Attention: MomentumNeonPalette.Yellow,
  AttentionContrast: MomentumNeonPalette.Darker,

  Danger: MomentumNeonPalette.Error,
  DangerContrast: MomentumNeonPalette.White,
};

const CornerRadius: ColorDefinition['CornerRadius'] = {
  Small: '4px',
  Base: '12px',
  Large: '16px',
};

const Elevation: ColorDefinition['Elevation'] = {
  Level1: '0 1px 2px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level2: '0 4px 6px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level3: '0 8px 12px var(--shadow-color, rgba(0, 0, 0, 0.15))',
};

const General: ColorDefinition['General'] = {
  Bonus: MomentumNeonPalette.Green,
  BonusContrast: MomentumNeonPalette.Black,
};

const Layout: ColorDefinition['Layout'] = {
  Background: MomentumNeonPalette.Midnight,
  BrandBorder: Primitive.Primary,
  BrowserTheme: MomentumNeonPalette.Midnight,
  LoadingColor: Primitive.Primary,
  Overlay: MomentumNeonPalette.Black + 'CC',
  Sidebar: {
    Background: MomentumNeonPalette.Midnight,
    Foreground: MomentumNeonPalette.Lightest,
  },
};

const Hero: ColorDefinition['Hero'] = {
  Heading: MomentumNeonPalette.White,
  Text: MomentumNeonPalette.White,
  TextHighlight: Primitive.Primary,

  Button: {
    Solid: Primitive.Primary,
    Rest: Primitive.Primary,
    Hover: Primitive.PrimaryTint,
    Active: Primitive.PrimaryTint,
    Text: Primitive.PrimaryContrast,
  },
};

export const Color: ColorDefinition = {
  TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,
};
